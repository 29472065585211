export default [
  {
    url: "https://www.amazon.com/dp/0578706245/ref=cm_sw_em_r_mt_dp_ATBVFbMNNQM4P",
    text: "amazon",
  },
  {
    url: "https://www.barnesandnoble.com/w/three-invaders-saleem-abdulrauf/1138144947?ean=9780578701912",
    text: "Barnes & Noble ",
  },
  // {
  //   url: "https://www.walmart.com/",
  //   text: "Walmart",
  // },
  // {
  //   url: "https://www.target.com/",
  //   text: "Target",
  // },
  // {
  //   url: "https://www.booksamillion.com/",
  //   text: "Book-a-million",
  // },
  // {
  //   url: "https://www.powells.com/",
  //   text: "powell's",
  // },
  // {
  //   url: "https://www.indiebound.org/",
  //   text: "indiebound",
  // },
  // {
  //   url: "https://bookshop.org/",
  //   text: "bookshop",
  // },
];
